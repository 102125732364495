import React from "react";
import Footer from "../components/MyFooter";
import DetailsPage from "../components/DetailsPage";

const DetailsPageContainer = () => {
  return (
    <>
      <DetailsPage />
    </>
  );
};

export default DetailsPageContainer;
