import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { fetchInvoiceDetails } from "../services/api"; // Import updated service function
import "../styles/InvoicePage.css"; // Adjusted import path
import MyFooter from "./MyFooter";

const DetailsPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // Get the value of a specific query parameter
  const myQueryParam = queryParams.get("oid"); // e.g., ?myParam=value

  const [orderData, setOrderData] = useState({
    invoiceID: 0,
    orderID: 0,
    subTotal: 0,
    taxAmount: 0,
    totalAmount: 0,
    paidAmount: 0,
    remainingAmount: 0,
    approxTotalWeight: 0,
    disPer: 0,
    discountAmt: 0,
    beforDisAmt: 0,
    noOfItems: 0,
    speedTypeID: 0,
    laundryTypeID: 0,
    serviceID: 0,
    isDiscount: false,
    speedTypeName: "",
    laundryTypeName: "",
    orderDate: "",
    orderNo: "",
    serviceName: "",
    perKGAmount: 0,
    responseObject: [],
  });

  useEffect(() => {
    fetchItemData();
  }, [navigate]);

  const fetchItemData = async () => {
    try {
      const response = await fetchInvoiceDetails(myQueryParam);
      if (response.data.isSuccess) {
        setOrderData(response.data);
      } else {
        setOrderData([]);
      }
    } catch (error) {
      console.error("Error fetching order data:", error);
      navigate("/error");
    }
  };

  return (
    <>
      <div className="invoice-container">
        <div className="table-responsive">
          <table className="invoice-table">
            <thead>
              <tr>
                <th style={{ width: "10%" }}>Sr.</th>
                <th style={{ width: "15%" }}>Category</th>
                <th style={{ width: "40%" }}>Item</th>
                {orderData.serviceID !== 5 && (
                  <th style={{ width: "10%" }}>Rate</th>
                )}
                <th style={{ width: "10%" }}>Qty</th>
                {/* {orderData.serviceID === 5 && (
                <th style={{ width: "10%" }}>Approx Weight</th>
              )} */}
                {orderData.serviceID !== 5 && (
                  <th style={{ width: "15%" }}>Total</th>
                )}
              </tr>
            </thead>
            <tbody>
              {orderData.responseObject.length > 0 ? (
                orderData.responseObject.map((item, index) => (
                  <tr key={item.orderDetailID}>
                    <td>{index + 1}</td>
                    <td>{item.itemCategoryName}</td>
                    <td>{item.itemName}</td>
                    {orderData.serviceID !== 5 && <td>₹ {item.rateOnOrder}</td>}
                    <td>{item.qty}</td>
                    {/* {orderData.serviceID === 5 && <td>{item.approxWeight} kg</td>} */}
                    {orderData.serviceID !== 5 && <td>₹ {item.lineTotal}</td>}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">No data available</td>
                </tr>
              )}

              {/* Before Discount */}
              {orderData.isDiscount && (
                <tr>
                  <td
                    style={{ textAlign: "right", paddingRight: "15px" }}
                    colSpan={orderData.serviceID === 5 ? "3" : "5"}
                  >
                    Before Discount
                  </td>
                  <td>₹ {orderData.beforDisAmt}</td>
                </tr>
              )}

              {/* Laundry Type */}
              {orderData.serviceID === 5 && (
                <tr>
                  <td
                    style={{ textAlign: "right", paddingRight: "15px" }}
                    colSpan="3"
                  >
                    Laundry Type ( Per KG Amount )
                  </td>
                  <td>
                    {orderData.laundryTypeName} ( ₹ {orderData.perKGAmount} )
                  </td>
                </tr>
              )}

              {/* Approx Total Weight */}
              {orderData.serviceID === 5 && (
                <tr>
                  <td
                    style={{ textAlign: "right", paddingRight: "15px" }}
                    colSpan="3"
                  >
                    Approx Total Weight
                  </td>
                  <td>{orderData.approxTotalWeight} kg</td>
                </tr>
              )}

              {/* Sub Total */}
              {/* <tr>
              <td
                style={{ textAlign: "right", paddingRight: "15px" }}
                colSpan={orderData.serviceID === 5 ? "3" : "5"}
              >
                Sub Total
              </td>
              <td>₹ {orderData.subTotal}</td>
            </tr> */}

              {/* Discount */}
              {orderData.isDiscount && (
                <tr>
                  <td
                    style={{ textAlign: "right", paddingRight: "15px" }}
                    colSpan={orderData.serviceID === 5 ? "3" : "5"}
                  >
                    Discount ( {orderData.disPer}% )
                  </td>
                  <td>₹ {orderData.discountAmt}</td>
                </tr>
              )}

              {/* Tax Amount */}
              {/* <tr>
              <td
                style={{ textAlign: "right", paddingRight: "15px" }}
                colSpan={orderData.serviceID === 5 ? "3" : "5"}
              >
                Tax Amount
              </td>
              <td>₹ {orderData.taxAmount}</td>
            </tr> */}

              {/* Total Amount (INR) */}
              <tr style={{ fontWeight: "bold" }}>
                <td
                  style={{ textAlign: "right", paddingRight: "15px" }}
                  colSpan={orderData.serviceID === 5 ? "3" : "5"}
                >
                  Total Amount (INR)
                </td>
                <td>₹ {orderData.totalAmount}</td>
              </tr>

              {/* Paid Amount */}
              {/* <tr style={{ color: "green", fontWeight: "bold" }}>
              <td
                style={{ textAlign: "right", paddingRight: "15px" }}
                colSpan={orderData.serviceID === 5 ? "3" : "5"}
              >
                Paid Amount
              </td>
              <td>₹ {orderData.paidAmount}</td>
            </tr> */}

              {/* Remaining Amount */}
              {/* <tr style={{ color: "red", fontWeight: "bold" }}>
              <td
                style={{ textAlign: "right", paddingRight: "15px" }}
                colSpan={orderData.serviceID === 5 ? "3" : "5"}
              >
                Remaining Amount
              </td>
              <td>₹ {orderData.remainingAmount}</td>
            </tr> */}
            </tbody>
          </table>
        </div>
      </div>
      <MyFooter />
    </>
  );
};

export default DetailsPage;
