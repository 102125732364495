import React from 'react';
import PdfViewerPage from '../components/PdfViewerPage';
import Footer from '../components/MyFooter';

const PdfViewerPageContainer = () => {
  return (
    <>
      <PdfViewerPage />
    </>
  );
};

export default PdfViewerPageContainer;
