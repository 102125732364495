import React from "react";
import InvoicePage from "../components/InvoicePage";
import Footer from "../components/MyFooter";

const InvoicePageContainer = () => {
  return (
    <>
      <InvoicePage />
    </>
  );
};

export default InvoicePageContainer;
